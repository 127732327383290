<template>
  <div class="page">
    <van-nav-bar title="设置"></van-nav-bar>
    <van-cell-group inset style="margin-top: 16px" v-if="isAdmin">
      <van-cell title="用户管理" is-link to="/user/list" />
      <van-cell title="角色管理" is-link to="/rolePermission" />
    </van-cell-group>
    <van-cell-group inset style="margin-top: 16px" v-if="isAdmin">
<!--      <van-cell title="柜子管理" is-link />-->
      <van-cell title="用户柜子管理" is-link to="/userCabinet" />
    </van-cell-group>
    <van-cell-group inset style="margin-top: 16px" v-if="isAdmin">
      <van-cell title="推送消息" is-link to="/pushMessage" />
    </van-cell-group>
    <van-button type="danger" style="margin-top: 20px" block @click="logout">注销</van-button>
  </div>
</template>

<script>
export default {
  name: "settings",
  computed: {
    isAdmin() {
      return this.$store.getters.getProfile.roles.includes('admin')
    }
  },
  methods: {
    async logout() {
      try {
        let res = await this.$http.post('logout')
        if (res.code === 200) {
          localStorage.removeItem('token')
          await this.$router.replace('/')
        }
      } catch (e) {
        console.log(e)
        if (e.message === 'Request failed with status code 405') {
          localStorage.removeItem('token')
          await this.$router.replace('/')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
